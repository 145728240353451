import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { AuthService } from "./auth.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ToastNotifierService } from "../core/services/toast-notifier.service";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    constructor(
        private toastNotifierService: ToastNotifierService,
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        private router: Router) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.afAuth.idTokenResult.pipe(
            map(userData => {
                if (userData == null) {
                    this.router.navigate(['/']);
                }

                //Si la página tiene permisos validar si los cumple
                const requiredPermissions = next?.data?.permissions?.only;
                if (requiredPermissions) {
                    return this.checkUserRoles(userData, requiredPermissions);
                }
                return true;

            }));
    }
    private checkUserRoles(user, targetPermissions: any) {
        let roles = this.getRoles(user);
        let isOk: boolean = false;
        if (roles) {
            //Comprobar si el usuario tiene alguno de los perfiles permitidos
            let permissionArray = Array.isArray(targetPermissions) ? targetPermissions : [targetPermissions];
            isOk = permissionArray.some(x => roles.includes(x));
        }

        if (isOk) {
            return true;
        }
        else {
            //Si el usuario no tiene ningún rol: no puede acceder al panel
            if (!roles) {
                this.toastNotifierService.fail("Permisos insuficientes");
                this.authService.SignOut();
                this.router.navigate(['/']);
            }
            //Si el usuario tiene algún rol: mostrar página de acceso denegado
            else {
                this.router.navigate(['/access']); //pagina acceso denegado
            }

            return false;
        }
    }


    private getRoles(user) {
        if (user?.claims?.role)
            return Array.isArray(user.claims.role) ? user.claims.role : [user.claims.role];
        else return null;
    }



}