import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class VersionsService {

    constructor(private http: HttpClient) { }

    getVersions() {
        return this.http.get<any>(environment.api + '/v1/versions');
    }




}