import { Component, ViewChild } from '@angular/core';
import { VersionsComponent } from 'src/app/pages/versions/versions.component';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrls: ['./app.footer.component.scss']

})
export class AppFooterComponent {
    currentYear: string = "";
    currentApplicationVersion = environment.appVersion;
    @ViewChild('versionsComponent') versionsComponent: VersionsComponent;
    constructor(public app: AppComponent) { }

    ngOnInit() {
        const currentDate = new Date();
        this.currentYear = currentDate.getFullYear().toString();
    }

    openVersions() {
        this.versionsComponent.showDialog();
    }
}

