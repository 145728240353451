export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  app: 'https://dev.iqsports.app',
  api: 'https://dev.api.iqsports.app',
  storageUrl: 'https://iqscoutingdev.blob.core.windows.net',
  storagePills: "pills",
  storageItems: "icons",
  storageTactics: "tactics",
  storageTacticEditor: "tactic-editor",
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyC6sVKaByCJKWVBnFBrMqiZPalSu1ACT0E',
    authDomain: 'iq-scouting-dev.firebaseapp.com',
    databaseURL: 'https://iq-scouting-dev.firebaseio.com',
    projectId: 'iq-scouting-dev',
    storageBucket: 'iq-scouting-dev.appspot.com',
    messagingSenderId: '316322467122',
    appId: '1:316322467122:web:996bbf1b8658b2ac3f9b2b',
    measurementId: 'G-QGY2Y7C5QJ'
  },
  sentry: {
    dsn: 'https://8368badf2c364070847fa582bf8be21a@sentry.io/5179391',
    includeRequestPayload: true,
    sendDefaultPii: true,
    minimumBreadcrumbLevel: 'Error',
    minimumEventLevel: 'Error',
    attachStackTrace: true,
    debug: true,
    diagnosticsLevel: 'Error',
    environment: "develop"
  }
};