import { Component, EventEmitter, OnInit, Output, Version } from "@angular/core";
import { RoleType } from "src/app/core/const/role";
import { VersionsService } from "./versions.service";
import { take } from "rxjs";

@Component({
    selector: 'app-versions',
    templateUrl: './versions.component.html',
    styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit {


    @Output() getVersions = new EventEmitter<any>();


    Roles = RoleType;
    loading: boolean = true;
    display: boolean;
    saving: boolean;
    empty: boolean = false;
    versions: Version[];



    constructor(private versionsService: VersionsService,
    ) {
    }


    ngOnInit() {


    }

    showDialog() {

        this.display = true;
        this.loading = true;
        this.empty = false;
        this.versionsService.getVersions().pipe(take(1)).subscribe(result => {
            this.versions = result.items;
            this.loading = false;
            if (this.versions && this.versions.length == 0) {
                this.empty = true;
            }

        })

    }

    hideDialog() {
        this.display = false;
        //this.quizForm.reset();
    }

}