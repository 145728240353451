import { Component } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-left">
                <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isOverlay() || appMain.isMobile()">
                    <i class="pi pi-bars"></i>
                </a>
            </div>

            <app-menu></app-menu>
        </div>
    `
})
export class AppTopbarComponent {

    constructor(public app: AppComponent, public appMain: AppMainComponent) { }

}
