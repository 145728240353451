<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}"
    *ngIf="!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())">
    <a class="layout-inline-menu-action flex p-dir-row align-items-center"
        (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <p-avatar label="{{avatar}}"></p-avatar>
        <span class="layout-inline-menu-text flex flex-column ml-2">{{displayName}}</span>
        <i class="layout-inline-menu-icon pi pi-angle-down ml-auto"></i>
    </a>

    <ul class="layout-inline-menu-action-panel"
        [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center" (click)="logout()">
                <i class="pi pi-power-off pi-fw"></i>
                <span>Salir</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center" routerLink="/account/configuration">
                <i class="pi pi-cog pi-fw"></i>
                <span>Configuración</span>
            </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center">
                <i class="pi pi-user pi-fw"></i>
                <span>Perfil</span>
            </a>
        </li>
    </ul>
</div>