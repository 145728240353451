<div class="exception-body notfound">
    <div class="exception-panel">
        <h1>404</h1>
        <h3>not found</h3>
        <p>La página que buscas no existe</p>
        <button type="button" pButton label="Volver al dashboard" [routerLink]="['/']"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/iqscouting-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
            class="exception-appname" />
    </div>
</div>