import { Component } from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { RoleType } from 'src/app/core/const/role';
import { AuthService } from 'src/app/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getDisplayName, getInitials } from 'src/app/core/functions';
import { take } from 'rxjs';

@Component({
    selector: 'app-inlinemenu',
    templateUrl: './app.inlinemenu.component.html',
    animations: [
        trigger('inline', [
            state('hidden', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*',
            })),
            state('hiddenAnimated', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineMenuComponent {
    user: any;
    avatar: string;
    displayName: string;

    constructor(public appMain: AppMainComponent,
        public afAuth: AngularFireAuth,
        public authService: AuthService) {

        this.afAuth.authState.pipe(take(1)).subscribe((user) => {
            if (user) {
                this.user = user;
                this.avatar = getInitials(this.user);
                this.displayName = getDisplayName(this.user);
            }
        });
    }



    checkRoles(role) {
        var isOkRole = false;
        if (role) {
            if (Array.isArray(role)) {
                // Si el usuario tiene un conjunto de permisos, nos va a venir un array con los roles
                isOkRole = role.findIndex(r => r == RoleType.ADMIN ||
                    r == RoleType.METHODOLOGY ||
                    r == RoleType.READONLY ||
                    r == RoleType.PILLS) != -1;
            }
            else if (typeof role === 'string') {
                // Si el usuario tiene un único rol, nos va a venir un string con el rol
                isOkRole = this.user.role === RoleType.ADMIN ||
                    this.user.role === RoleType.METHODOLOGY ||
                    this.user.role == RoleType.READONLY ||
                    this.user.role === RoleType.PILLS;
            }
        }
        return isOkRole;
    }

    logout() {
        sessionStorage.removeItem("token");
        this.authService.SignOut();
    }
}


