import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/compat/auth-guard';
import { AuthGuard } from './auth/auth.guard';
import { RoleType } from './core/const/role';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);
const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);


const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('src/app/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToDashboard },
    },
    {
        path: 'dashboard',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/dashboard/dashboard.module')
                .then(m => m.DashboardModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN,
                RoleType.METHODOLOGY,
                RoleType.PILLS,
                RoleType.READONLY]
            }
        }
    },
    {
        path: 'users',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/usersauth/usersauth.module')
                .then(m => m.UsersauthModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'clubs',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/clubs/clubs.module')
                .then(m => m.ClubsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'players',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/players/players.module')
                .then(m => m.PlayersModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'formsets',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/formsets/formsets.module')
                .then(m => m.FormSetsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/modules',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/learningmodules/modules.module')
                .then(m => m.ModulesModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY]
            }
        }
    },
    {
        path: 'methodology/templates',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/templates/templates.module')
                .then(m => m.TemplatesModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'methodology/items',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/items/items.module')
                .then(m => m.ItemsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/pills',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/pills/pills.module')
                .then(m => m.PillsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/quizzes',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/quizzes/quizzes.module')
                .then(m => m.QuizzesModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/quiz-questions',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/quizquestions/quiz-questions.module')
                .then(m => m.QuizQuestionsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/tactics',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/tactics/tactics.module')
                .then(m => m.TacticsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY]
            }
        }
    },
    {
        path: 'methodology/statistics',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/statistics/statistics.module')
                .then(m => m.StatisticsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY]
            }
        }
    },
    {
        path: 'configuration/seasons',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/seasons/seasons.module')
                .then(m => m.SeasonsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN,]
            }
        }
    },
    {
        path: 'configuration/positions',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/positions/positions.module')
                .then(m => m.PositionsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'configuration/categories',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/categories/categories.module')
                .then(m => m.CategoriesModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'configuration/icons',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/icons/icons.module')
                .then(m => m.IconsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'configuration/configurations',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/configurations/configurations.module')
                .then(m => m.ConfigurationsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },

    {
        path: 'configuration/appactions',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/appactions/appactions.module')
                .then(m => m.AppActionsModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },
    {
        path: 'coaches',
        component: AppMainComponent,
        loadChildren: () =>
            import('src/app/pages/coaches/coaches.module')
                .then(m => m.CoachesModule),
        canActivate: [AuthGuard], data: {
            permissions: {
                only: [RoleType.ADMIN]
            }
        }
    },

    {
        path: 'error',
        component: AppErrorComponent
    },
    { path: 'access', component: AppAccessdeniedComponent },
    { path: 'notfound', component: AppNotfoundComponent },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
