<p-dialog [(visible)]="display" [style]="{width: '45%'}" header="Últimas Versiones" [modal]="true" styleClass="fluid"
    (onHide)=hideDialog()>
    <div class="progress-spinner" *ngIf="loading">
        <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
    </div>
    <div *ngIf="!loading && versions">
        <div *ngFor="let item of versions">
            <div class="card">
                <div class="grid p-fluid">
                    <div class="col flex align-items-start flex-wrap">
                        <div class="version text-lg w-auto ml-2">V {{item.name}}</div>
                        <div class="date text-lg w-auto ml-2">{{item.date | date: 'dd/MM/yyyy'}}</div>
                    </div>
                </div>
                <ul class="sections" *ngFor="let change of item.changes">
                    <li class="change text-sm w-auto ml-2">{{change.description}} <span
                            [class]="'course-badge type-' + change.type">{{change.type}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="empty">
        <tr>
            <td colspan="8">No hay versiones disponibles.</td>
        </tr>
    </div>
</p-dialog>