import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { RoleType } from 'src/app/core/const/role';
import { NgxPermissionsService } from 'ngx-permissions';
import { loadUserRoles } from 'src/app/core/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IdTokenResult } from 'firebase/auth';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    public model: any[];

    constructor(public app: AppComponent,
        public appMain: AppMainComponent,
        private afAuth: AngularFireAuth,
        private permissionsService: NgxPermissionsService
    ) { }

    ngOnInit() {
        // Gestión de la visiblidad de los menús en base a los roles
        this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                loadUserRoles(user?.claims?.role, this.permissionsService);
            }
        });

        this.loadMenu();

    }

    private loadMenu() {
        this.model = [
            {
                label: 'Dashboard', iconFamily: 'fontawesome', icon: 'chart-area', routerLink: ['/'],
                show: true
            },
            {
                label: 'Usuarios', iconFamily: 'fontawesome', icon: 'user-lock', routerLink: ['/users'], roles: [RoleType.ADMIN],
                show: true
            },
            {
                label: 'Clubes', iconFamily: 'fontawesome', icon: 'volleyball-ball', routerLink: ['/clubs'], roles: [RoleType.ADMIN],
                show: true
            },
            {
                label: 'Entrenadores', iconFamily: 'fontawesome', icon: 'person-chalkboard', routerLink: ['/coaches'], roles: [RoleType.ADMIN],
                show: true
            },
            {
                label: 'Jugadores', iconFamily: 'fontawesome', icon: 'running', routerLink: ['/players'], roles: [RoleType.ADMIN],
                show: true
            },
            {
                label: 'Cuestionarios', iconFamily: 'fontawesome', icon: 'comments', routerLink: ['/formsets'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.READONLY],
                show: true
            },

            {
                label: 'Metodología', iconFamily: 'fontawesome', icon: 'chalkboard-user', routerLink: ['/methodology'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY],
                items: [
                    {
                        label: 'Módulos educativos', iconFamily: 'fontawesome', icon: 'book', routerLink: ['/methodology/modules'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY],
                        show: true
                    },
                    {
                        label: 'Plantillas', iconFamily: 'fontawesome', icon: 'columns', routerLink: ['/methodology/templates'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Items', iconFamily: 'fontawesome', icon: 'list-ul', routerLink: ['/methodology/items'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY],

                    },
                    {
                        label: 'Píldoras', iconFamily: 'fontawesome', icon: 'capsules', routerLink: ['/methodology/pills'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY],
                        show: true
                    },
                    {
                        label: 'Concursos', iconFamily: 'fontawesome', icon: 'graduation-cap', routerLink: ['/methodology/quizzes'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.READONLY],
                        show: true
                    },

                    {
                        label: 'Preguntas concurso', iconFamily: 'fontawesome', icon: 'circle-question', routerLink: ['/methodology/quiz-questions'], roles: [RoleType.ADMIN, RoleType.PILLS, RoleType.METHODOLOGY, RoleType.READONLY],
                        show: true
                    },
                    {
                        label: 'Tácticas', iconFamily: 'fontawesome', icon: 'chess', routerLink: ['/methodology/tactics'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY],
                        show: true
                    },

                    {
                        label: 'Estadísticas', iconFamily: 'fontawesome', icon: 'chart-line', routerLink: ['/methodology/statistics'], roles: [RoleType.ADMIN, RoleType.METHODOLOGY, RoleType.PILLS, RoleType.READONLY],
                        show: true
                    }
                ],

                show: true
            },
            {
                label: 'Configuración', iconFamily: 'fontawesome', icon: 'screwdriver-wrench', routerLink: ['/configuration'], roles: [RoleType.ADMIN],
                items: [
                    {
                        label: 'Temporadas', iconFamily: 'fontawesome', icon: 'calendar-alt', routerLink: ['/configuration/seasons'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Posiciones', iconFamily: 'fontawesome', icon: 'project-diagram', routerLink: ['/configuration/positions'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Categorias', iconFamily: 'fontawesome', icon: 'shapes', routerLink: ['/configuration/categories'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Iconos', iconFamily: 'fontawesome', icon: 'icons', routerLink: ['/configuration/icons'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Configuraciones', iconFamily: 'fontawesome', icon: 'screwdriver', routerLink: ['/configuration/configurations'], roles: [RoleType.ADMIN],
                        show: true
                    },
                    {
                        label: 'Puntuaciones', iconFamily: 'fontawesome', icon: 'star', routerLink: ['/configuration/appactions'], roles: [RoleType.ADMIN],
                        show: true
                    }
                ],
                show: true
            },
        ];
    }

}
