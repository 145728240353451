import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: 'root',
})
export class ToastNotifierService {
    constructor(private messageService: MessageService
        , private translateService: TranslateService
    ) {

    }

    public fail(error: any, errorSummary: string = null, toastKey?: string) {

        if (!toastKey) {
            toastKey = "bc"
        }
        this.showErrorMessage(toastKey, error, errorSummary);
    }

    public success(summary: string, detailMessage: string = '', toastKey?: string) {
        if (!toastKey) {
            toastKey = "bc"
        }

        this.showSuccessMessage(toastKey, summary, detailMessage);
    }


    private showErrorMessage(toastKey: string, error: any, summary: string) {
        if (error) {
            var errorCodes = [];
            if (error.error && error.error.errors
                && Array.isArray(error.error.errors)) {
                error.error.errors.forEach((e) => {
                    errorCodes.push("ERRORS." + e);
                });
                this.translateService.get(errorCodes).subscribe(tr => {
                    let keyTrErrors = Object.keys(tr);
                    let errorMesages = [];
                    for (var keyError of keyTrErrors) {
                        errorMesages.push(tr[keyError]);
                    }
                    var errorMessage = errorMesages ? errorMesages.map(x => x).join(",") : "";
                    this.messageService.add({ key: toastKey, severity: 'error', summary: (summary ? summary : 'Mensaje de error'), detail: errorMessage });
                });
            }
            else {
                let detailMessage = (typeof error === 'string') ? error : "";
                this.messageService.add({ key: toastKey, severity: 'error', summary: (summary ? summary : 'Mensaje de error'), detail: detailMessage });
            }
        }
        else {
            this.messageService.add({ key: toastKey, severity: 'error', summary: (summary ? summary : 'Mensaje de error'), detail: "" });
        }
    }

    private showSuccessMessage(toastKey, summary, detailMessage) {
        this.messageService.add({ key: toastKey, severity: 'success', summary: (summary ? summary : 'Mensaje de éxito'), detail: detailMessage });
    }
}
