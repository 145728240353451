import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

// Application Components
import { AppComponent, createTranslateLoader } from './app.component';
import { AppBreadcrumbComponent } from 'src/app/layout/breadcrumb/app.breadcrumb.component';
import { AppMainComponent } from './app.main.component';
import { AppRightMenuComponent } from './layout/rightmenu/app.rightmenu.component';
import { AppInlineMenuComponent } from './layout/inlinemenu/app.inlinemenu.component';
import { AppMenuComponent } from './layout/menu/app.menu.component';
import { AppMenuitemComponent } from './layout/menu/app.menuitem.component';
import { AppTopbarComponent } from './layout/topbar/app.topbar.component';
import { AppFooterComponent } from './layout/footer/app.footer.component';


// Application services
import { MenuService } from './layout/menu/app.menu.service';
import { AppBreadcrumbService } from './layout/breadcrumb/app.breadcrumb.service';

// iFormación Pages
import { ConfigurationComponent } from './pages/account/configuration/configuration.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { ConfirmationService, MessageService } from 'primeng/api';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';


import { environment } from 'src/environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

// Roles component
import { NgxPermissionsModule } from 'ngx-permissions';
import { VersionsModule } from './pages/versions/versions.module';

//Locales
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { registerLocaleData } from '@angular/common';
import { ToastNotifierService } from './core/services/toast-notifier.service';
import { TabViewModule } from 'primeng/tabview';
import { AuthService } from './auth/auth.service';
import { TokenInterceptor } from './auth/auth.interceptor';
import { GlobalErrorHandler } from './core/global-error.handler';

registerLocaleData(localeEs, 'es-ES', localeExtraEs);
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ProgressSpinnerModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        TableModule,
        CarouselModule,
        ProgressBarModule,
        ChartModule,
        DropdownModule,
        AvatarModule,
        AccordionModule,
        CalendarModule,
        InputTextModule,
        ToastModule,
        FontAwesomeModule,
        VersionsModule,
        TabViewModule,
        ToastModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'es-ES',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
    ],
    declarations: [
        AppComponent,
        AppBreadcrumbComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppRightMenuComponent,
        AppInlineMenuComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        ConfigurationComponent,
    ],
    providers: [
        AuthService
        , MenuService
        , AppBreadcrumbService
        , ConfirmationService
        , MessageService
        , TranslateService
        , ToastNotifierService
        , {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: "es-ES" },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        // Add an icon to the library for convenient access in other components
        library.addIconPacks(fas, far);
    }
}
