<div class="exception-body accessdenied">
    <div class="exception-panel">
        <h1>Acceso Denegado</h1>
        <p>Lamentablemente, no tienes permiso para acceder a esta página.</p>
        <button type="button" pButton label="Volver a la Página Principal" [routerLink]="['/']"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/iqscouting-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
            class="exception-appname" />
    </div>
</div>