<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{'layout-static': app.menuMode === 'static',
                'layout-overlay': app.menuMode === 'overlay',
                'layout-slim': app.menuMode === 'slim',
                'layout-horizontal': app.menuMode === 'horizontal',
                'layout-sidebar': app.menuMode === 'sidebar',
                'layout-sidebar-static': app.menuMode === 'sidebar' && sidebarStatic,
                'layout-overlay-active': overlayMenuActive,
                'layout-mobile-active': staticMenuMobileActive,
                'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
                 'p-ripple-disabled': !app.ripple}">

    <div class="layout-main">

        <app-topbar *ngIf="isMobile() || isOverlay() || isHorizontal()"></app-topbar>

        <app-menu *ngIf="!isMobile() && (isStatic() || isSlim() || isSidebar())"></app-menu>

        <app-breadcrumb></app-breadcrumb>

        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <app-rightmenu></app-rightmenu>
    <p-toast position="bottom-center" key="bc"></p-toast>

</div>