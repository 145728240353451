import { Injectable, NgZone } from '@angular/core';
import { User } from './user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore,
    AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastNotifierService } from '../core/services/toast-notifier.service';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any; // Save logged in user data
    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private toastNotifierService: ToastNotifierService,
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        /* Saving user data in localstorage when 
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userData = user;

                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user')!);
            } else {
                localStorage.setItem('user', 'null');
                JSON.parse(localStorage.getItem('user')!);
            }
        });
    }
    // Sign in with email/password
    SignIn(email: string, password: string) {
        if (email && password) {
            return this.afAuth
                .signInWithEmailAndPassword(email, password)
                .then((result) => {
                    //this.SetUserData(result.user);
                    this.afAuth.authState.subscribe((user) => {
                        if (user) {
                            this.router.navigate(['dashboard']);
                        }
                    });
                })
                .catch((error) => {
                    if (error.code == "auth/user-not-found") {
                        this.toastNotifierService.fail('El usuario especificado no existe en nuestro sistema. Verifica que has ingresado la información correcta.');
                    }
                    else if (error.code == "auth/wrong-password") {
                        this.toastNotifierService.fail('Contraseña incorrecta. Por favor, verifica tu contraseña e inténtalo de nuevo.');
                    }
                    else {
                        if (!environment.production) console.log(error);
                        this.toastNotifierService.fail("Se ha producido un error.");
                    }
                });
        }
        else {
            this.toastNotifierService.fail("Rellena los campos obligatorios");
        }

    }
    // Sign up with email/password
    SignUp(email: string, password: string) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign 
                up and returns promise */
                this.SendVerificationMail();
                //this.SetUserData(result.user);
            })
            .catch((error) => {
                if (error.code == "auth/email-already-in-use") {
                    this.toastNotifierService.fail('El correo electrónico indicado ya está en uso');
                }
                else if (error.code == "auth/weak-password") {
                    this.toastNotifierService.fail('La contraseña que ingresaste es demasiado corta. Asegúrate de que tenga al menos 6 caracteres para garantizar la seguridad de tu cuenta.');
                }
                else {
                    if (!environment.production) console.log(error);
                    this.toastNotifierService.fail("Se ha producido un error.");
                }
            });
    }
    // Send email verfificaiton when new user sign up
    SendVerificationMail() {

        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification())
            .then(() => {
                this.router.navigate(['verify-email-address']);
            });
    }
    // Reset Forggot password
    ForgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                this.toastNotifierService.success('Te hemos enviado un correo para reetablecer tu contraseña, mira en tu bandeja de entrada.');
            })
            .catch((error) => {
                this.toastNotifierService.fail('No se ha podio recuperar la contraseña.');
            });
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null;
    }
    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
            this.router.navigate(['dashboard']);
        });
    }
    // Auth logic to run auth providers
    AuthLogin(provider: any) {
        return this.afAuth
            .signInWithPopup(provider)
            .then((result) => {
                this.router.navigate(['dashboard']);
                //this.SetUserData(result.user);
            })
            .catch((error) => {
                this.toastNotifierService.fail("Se ha producido un error.");
            });
    }
    /* Setting up user data when sign in with username/password, 
    sign up with username/password and sign in with social auth  
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user: any) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );
        const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
        };
        return userRef.set(userData, {
            merge: true,
        });
    }
    // Sign out
    SignOut() {
        return this.afAuth.signOut().then(() => {
            this.router.navigate(['/']);
            localStorage.removeItem('user');
        });
    }

}