import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { User } from "firebase/auth";
import { NgxPermissionsService } from 'ngx-permissions';
import { GroupPosition } from "./const/player";

export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateAllFormFields(control);
        }
        else if (control instanceof UntypedFormArray) {
            control.controls.forEach((itemForm: UntypedFormGroup) => {
                validateAllFormFields(itemForm);
            });
        }
    });
}

export function toDateOnlyString(date: Date) {
    if (!date)
        return null;
    else
        return `${date.getFullYear()}-${returnMonth()}-${returnDay()}`;

    function returnDay() {
        var d = (date.getDate() < 10) ? "0" + (date.getDate()) : date.getDate();
        return d;
    }
    function returnMonth() {
        var m = (date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return m;
    }
}


// Método que obtiene los roles del usuario de la sesión y los carga a través de la librería NgxPermissions.
// Esto les permite tenerlos disponibles en las vistas html y poder gestionar la visibilidad de los diferentes componentes del html en base a los roles
export function loadUserRoles(claimsRole, permissionsService: NgxPermissionsService) {

    if (claimsRole) {
        const perm = claimsRole;
        if (perm) {
            if (Array.isArray(perm)) {
                permissionsService.loadPermissions(perm);
            }
            else {
                permissionsService.loadPermissions([perm]);
            }
            // Almacenamos los permisos del usuario en el localStorage para tenerlos disponibles
            window.localStorage.setItem("permissions", JSON.stringify(perm));
        }
    }
    else { window.localStorage.removeItem("permissions"); }
}

// Método que comprueba si el usuario tiene uno o más roles que vienen en targetRoles
export async function hasRoles(user, targetRoles) {
    //var afAuth = AngularFireAuth;
    var isOkRole = false;
    var perm = null;

    if (user) {
        //obtenemos el rol del usuario que puede ser uno o varios roles

        const perm = Array.isArray(user) ? user : [user];
        if (perm) {
            if (targetRoles) {
                // Se guarda la lista de roles a comprobar en un array para facilitar su manejo
                const rolesArray = Array.isArray(targetRoles) ? targetRoles : [targetRoles];
                isOkRole = perm.some((x) => rolesArray.includes(x));
            }
        }
    }

    return isOkRole;
}

var hexDigits = new Array
    ("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f");



export function rgb_rgbaTohex(rgb) {
    if (rgb.indexOf("rgba") == -1) {
        rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
        return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    }
    else {

        var rgba = rgb.match(
            /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
        );
        return rgba && rgba.length === 4
            ? "#" +
            ("0" + parseInt(rgba[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgba[3], 10).toString(16)).slice(-2)
            : "";

    }
}



export function hex(x) {
    return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
}

export function getPositionGroupIcon(group: number) {

    var icon;
    switch (group) {
        case GroupPosition.GOALKEEPER:
            icon = "icon-portero";
            break;
        case GroupPosition.DEFENDER:
            icon = "icon-defensa";
            break;
        case GroupPosition.MIDFIELDER:
            icon = "icon-mediocampo";
            break;
        case GroupPosition.FORWARD:
            icon = "icon-delantero";
            break;
    }

    return icon;
}

//Obtiene la hora local
export function getLocalDate(date) {

    const now = new Date();
    var tempDate = new Date(date);
    return new Date(tempDate.getTime() - (now.getTimezoneOffset() * 60000));
}

export function getInitials(user: User) {
    let initials = "";
    let name = "";
    if (user.displayName) {
        name = user.displayName;
    }
    else {
        name = user.email;
    }
    let initialsArray = name.split(' ');
    if (initialsArray.length > 1) {
        initials += initialsArray[0][0] + initialsArray[1][0];
    }
    else {
        initials += initialsArray[0][0] + initialsArray[0][1];
    }
    return initials;
}

export function getDisplayName(user: User) {
    if (user.displayName) {
        return user.displayName;
    }
    else {
        return user.email;
    }
}
